
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CustomToolbarBtn extends Vue {
  @Prop({ required: true, default: false }) isSelected: boolean
  @Prop({ required: true }) tabLabel: string
  @Prop({ required: true }) tab: string

  changeTab () {
    this.$emit('changeTab', this.tab)
  }
}
