
import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator'
import * as rules from '../../../../rules/rules'
@Component({})
export default class EmailInputDeck extends Vue {
  @PropSync('emailSync', { type: String, required: true, default: '' })
  email: string
  @Prop({ required: true, default: 0 }) isDisabled: boolean

  rules = rules

  emailErrorMessages: string[] = []

  @Watch('email')
  async onEmailSearchChange (email: string) {
    let errors: string[] = []

    // check for required
    let ruleCheck: string | boolean = rules.required(email)
    let toReturn = ruleCheck !== true ? 'Field is required' : ''
    if (toReturn !== '') {
      errors.push(toReturn)
    } else {
      // check email validity
      ruleCheck = rules.email(email)
      toReturn = ruleCheck !== true ? 'Invalid email' : ''
      if (toReturn !== '') {
        errors.push(toReturn)
      }
      if (!email.includes('@scibids.com') && !email.includes('@doubleverify.com')) {
        errors.push('Please send to a DoubleVerify or Scibids mail address')
      }
    }
    this.emailErrorMessages = errors
  }
}
