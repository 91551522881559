
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { KpiText, KpiValue } from '../../../../../types/brief_enum'
import { isDsp } from '../../../../../types/instruction_type_helper'
import * as rules from '../../../../rules/rules'
import { SelectedIo } from '../../../../../types/instruction_type'

@Component({})
export default class KpiToOptimizeDeck extends Vue {
  @Prop({ required: true }) dsp: string
  @Prop({ required: false, default: null }) activeSelectedIos: SelectedIo
  @Prop({ required: true, default: '' }) kpiToOptimize: KpiValue
  @PropSync('selectedKpiSync', { required: true, default: '' })
  selectedKpi: KpiValue

  rules = rules
  get kpiMessages (): string[] {
    let msg: string[] = []
    if (this.hasKpiToOptimize && (this.selectedKpi !== this.kpiToOptimize)) {
      msg.push('Selected KPI is different than IO\'s KPI_to_optimize')
    }
    return msg
  }

  get kpiOptions (): Array<{ value: KpiValue, text: KpiText }> {
    if (isDsp(this.dsp)) {
      if (Object.keys(this.$dspConfig[this.dsp]).indexOf('kpiToOptimizeOptions') === -1) {
        console.warn(`kpiToOptimizeOptions is not a key of dspConfig for the dsp ${this.dsp}. No Kpi options will be displayed for insights.`)
        return []
      }
      // Filter out CPIAVC for dbm
      return this.$dspConfig[this.dsp].kpiToOptimizeOptions.map((kpi: KpiValue) => {
        return { value: kpi, text: this.$commonUtils.kpiValueToKpiText(kpi) }
      }).filter(x => x.value !== KpiValue.CPIAVC)
    }
    // invalid dsp for some reason
    console.warn(`Invalid dsp type for ${this.dsp}. No Kpi options will be displayed for insights.`)
    return []
  }

  get hasKpiToOptimize (): boolean {
    if (Object.entries(this.activeSelectedIos).length === 1) {
      const item = Object.entries(this.activeSelectedIos)[0]
      if (Object.values(KpiValue).indexOf(item[1].instruction.KPI_to_optimize as KpiValue) >= 0) {
        return true
      }
    }
    return false
  }

  get selectedAmount (): number {
    return Object.keys(this.activeSelectedIos).length
  }
}
