
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import FromToCalendar from '@/components/TableComponents/Form/Decks/FromToCalendar.vue'
import { KpiValue } from '../../../../../types/brief_enum'
import * as rules from '../../../../rules/rules'
import KpiToOptimizeDeck from '@/components/TableComponents/Form/Decks/KpiToOptimizeDeck.vue'
import EmailInputDeck from '@/components/TableComponents/Form/Decks/EmailInputDeck.vue'
import OverviewCombobox from '@/components/TableComponents/Form/FormFragments/OverviewCombobox.vue'
import { SelectedIo } from '../../../../../types/instruction_type'

@Component({
  components: {
    EmailInputDeck,
    FromToCalendar,
    KpiToOptimizeDeck,
    OverviewCombobox
  }
})
export default class DeckABTestForm extends Vue {
  @Prop({ required: false, default: null }) activeSelectedIos: SelectedIo
  @Prop({ required: true }) dsp: string
  @Prop({ required: true, default: '' }) kpiToOptimize: KpiValue
  @Prop({ required: true, default: false }) isOpenDialog: boolean

  @PropSync('startDateSync', { type: String, required: false, default: '' })
  startDate: string

  @PropSync('endDateSync', { type: String, required: false, default: '' })
  endDate: string

  @PropSync('emailSync', { type: String, required: true, default: '' })
  email: string

  @PropSync('selectedKpiSync', { required: true, default: '' })
  selectedKpi: KpiValue

  @PropSync('selectedExternalIdsSync', { required: true, default: [] })
  selectedExternalIds: string[]

  @PropSync('customABTestFeeSync', { required: true, default: 0 })
  customABTestFee: number

  rules = rules
  loadingBaselines = false
  async mounted () {
    this.resetForm(this.isOpenDialog)
    await this.loadBaselines()
  }

  resetForm (val: boolean) {
    if (!val) {
      this.startDate = ''
      this.endDate = ''
      this.email = this.defaultEmail
      this.selectedKpi = this.kpiToOptimize
      this.selectedExternalIds = []
    }
  }

  async loadBaselines () {
    const externalIds = Object.keys(this.activeSelectedIos)

    if (externalIds.length === 0) {
      console.warn('No IOs selected')
      return
    }

    if (externalIds.length > 1) {
      console.warn("Don't need to get baselines for more than one IO (no prefill)")
      return
    }
    const subDsp = this.$route.params.dsp
    const baselineType: BaselineType = 'ab_test'
    const aiPerf: AiPerformance | null = null
    this.loadingBaselines = true
    const response = await this.$apiCaller.getBaselines(
      externalIds,
      subDsp,
      aiPerf,
      baselineType)

    if (this.$apiCaller.isResponseError(response)) {
      this.$emit('error', `Failed to get baselines: ${response.errors}`)
    } else {
      const data = response.data
      if (data.length > 0) {
        // eslint-disable-next-line camelcase
        data.sort((a: { insertion_date: string }, b: { insertion_date: string }) => {
          return Number(new Date(b.insertion_date)) - Number(new Date(a.insertion_date))
        })
        const baseline = data[0]
        this.startDate = this.$commonUtils.dateToIsoCalendar(new Date(baseline.ab_test_start_date))
        this.endDate = this.$commonUtils.dateToIsoCalendar(new Date(baseline.ab_test_end_date))
        this.selectedExternalIds = baseline.baseline_io
      }
    }
    this.loadingBaselines = false
  }

  get selectedAmount (): number {
    return Object.keys(this.activeSelectedIos).length
  }

  get defaultEmail (): string {
    return this.$store.getters.getCurrentUserAvailableMail
  }

  @Watch('isOpenDialog')
  onOpenDialog (val: boolean) {
    this.resetForm(val)
  }
}
