
import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator'
import { KpiValue } from '../../../../../types/brief_enum'
import * as rules from '../../../../rules/rules'
import FromToCalendar from '@/components/TableComponents/Form/Decks/FromToCalendar.vue'
import KpiToOptimizeDeck from '@/components/TableComponents/Form/Decks/KpiToOptimizeDeck.vue'
import EmailInputDeck from '@/components/TableComponents/Form/Decks/EmailInputDeck.vue'
import { SelectedIo } from '../../../../../types/instruction_type'

@Component({
  components: { FromToCalendar, KpiToOptimizeDeck, EmailInputDeck }
})
export default class TroubleshootingForm extends Vue {
  @Prop({ required: false, default: null }) activeSelectedIos: SelectedIo
  @Prop({ required: true }) dsp: string
  @Prop({ required: true, default: false }) isOpenDialog: boolean
  @Prop({ required: true, default: '' }) kpiToOptimize: KpiValue

  @PropSync('startDateSync', { type: String, required: false, default: '' })
  startDate: string

  @PropSync('endDateSync', { type: String, required: false, default: '' })
  endDate: string

  @PropSync('emailSync', { type: String, required: true, default: '' })
  email: string

  @PropSync('selectedKpiSync', { required: true, default: '' })
  selectedKpi: KpiValue

  emailSearch: string = ''
  timeoutHandle: any = null
  statusLogsLoading: boolean = false
  overviewStatusLog: Array<StatusLog> = []

  rules = rules

  mounted () {
    this.getStatusLogs()
    this.resetForm(this.isOpenDialog)
  }

  resetForm (val: boolean) {
    if (val) {
      const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000
      const oneWeekAgo = new Date(Date.now() - oneWeekInMilliseconds)
      if (this.overviewStatusLog.length > 0) {
        // If there are status logs, we want to prefill the form with the last status log who is with LIVE status
        const liveStatusLogs = this.overviewStatusLog.filter((statusLog: StatusLog) => statusLog.status === Status.LIVE)
        if (liveStatusLogs) {
          // get the most recent live
          const mostRecentLive = liveStatusLogs.sort((a: StatusLog, b: StatusLog) => {
            return new Date(b.update_date).getTime() - new Date(a.update_date).getTime()
          })[0]
          const mostRecentLiveDate = new Date(mostRecentLive.update_date)

          const mostRecentDateBetweenTheBoth = mostRecentLiveDate > oneWeekAgo ? mostRecentLiveDate : oneWeekAgo
          this.startDate = this.$commonUtils.dateToIsoCalendar(mostRecentDateBetweenTheBoth)
        } else {
          console.warn('Can not find most recent live')
          this.startDate = this.$commonUtils.dateToIsoCalendar(oneWeekAgo)
        }
      } else {
        console.warn('No status logs')
        this.startDate = this.$commonUtils.dateToIsoCalendar(oneWeekAgo)
      }
      const today = new Date(Date.now()) // 1 day in millisecond notation
      this.endDate = this.$commonUtils.dateToIsoCalendar(today)
      this.selectedKpi = this.kpiToOptimize
      this.email = this.defaultEmail
    }
  }

  async getStatusLogs () {
    const externalIds = Object.keys(this.activeSelectedIos)

    if (externalIds.length === 0) {
      console.warn('No IOs selected')
      return
    }

    if (externalIds.length > 1) {
      console.warn("Don't need to get status log for more than one IO (no prefill)")
      return
    }

    const externalId = externalIds[0]

    this.statusLogsLoading = true
    let result = null
    result = await this.$apiCaller.getStatusLogs(externalId)
    if (this.$apiCaller.isResponseError(result, true)) {
      console.warn('Error when loading status logs.')
      this.statusLogsLoading = false
    } else {
      this.overviewStatusLog = result.data
    }
    this.statusLogsLoading = true
  }
  get selectedAmount (): number {
    return Object.keys(this.activeSelectedIos).length
  }

  get defaultEmail (): string {
    return this.$store.getters.getCurrentUserAvailableMail
  }

  @Watch('email')
  onEmailBufferChange (email: string) {
    if (this.timeoutHandle !== null) {
      clearTimeout(this.timeoutHandle)
    }
    this.timeoutHandle = window.setTimeout(() => {
      this.emailSearch = email
    }, 500)
  }

  @Watch('activeSelectedKpiList')
  setDefaultKpi () {
    this.selectedKpi = this.kpiToOptimize
  }

  @Watch('isOpenDialog')
  onOpenDialog (val: boolean) {
    this.resetForm(val)
  }
}
