
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'
import DatePicker from '@/components/TableComponents/Form/FormFragments/DatePicker.vue'

@Component({
  components: { DatePicker }
})
export default class FromToCalendar extends Vue {
  @Prop({ type: Boolean, default: false }) isDisabledStart!: boolean
  @Prop({ type: Boolean, default: false }) isDisabledEnd!: boolean
  @Prop({ type: Array, default: (): string[] => [] }) startDateErrorMessages!: string[]
  @Prop({ type: Array, default: (): string[] => [] }) endDateErrorMessages!: string[]
  @PropSync('startDate', { type: String, required: true }) startDateSync!: string
  @PropSync('endDate', { type: String, required: true }) endDateSync!: string

  get _endDateErrorMessages (): string[] {
    let error: string[] = []
    if (Date.parse(this.endDateSync) < Date.parse(this.startDateSync)) {
      error.push('The end date can not be before the start date')
    }
    return [...error, ...this.endDateErrorMessages]
  }
}
