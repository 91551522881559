
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { KpiValue } from '../../../../types/brief_enum'
import DatePicker from '@/components/TableComponents/Form/FormFragments/DatePicker.vue'
import InsightForm from '@/components/TableComponents/Form/Decks/InsightForm.vue'
import DeckABTestForm from '@/components/TableComponents/Form/Decks/DeckABTestForm.vue'
import { SelectedIo } from '../../../../types/instruction_type'
import TroubleshootingForm from '@/components/TableComponents/Form/Decks/TroubleshootingForm.vue'
import CustomToolbarBtn from '@/components/Common/CustomToolbarBtn.vue'

type TabDeckType = 'insight' | 'abtest' | 'troubleshooting'

@Component({
  components: { InsightForm, DatePicker, DeckABTestForm, TroubleshootingForm, CustomToolbarBtn }
})
export default class DecksDialog extends Vue {
  @Prop({ required: true, default: false }) value: boolean
  @Prop({ required: false, default: null }) selected: SelectedIo
  @Prop({ required: true }) dsp: string

  loadingReports: boolean = false
  currentTab: TabDeckType = 'insight'
  // insight form variables
  selectedKpi: KpiValue = null
  email: string = ''
  startDate: string = ''
  endDate: string = ''
  selectedExternalIds: string[] = []
  customABTestFee: number = null

  isValid: boolean = false

  changeTab (tab: TabDeckType) {
    this.currentTab = tab
  }

  close () {
    this.emitCloseInsight()
  }

  emitCloseInsight () {
    this.$emit('close-insight')
  }

  async submit () {
    const selectedArray = Object.entries(this.activeSelectedIos)
    const idList: Array<string | number> = selectedArray.map(([key, value]) => key)

    if (this.isInsight) {
      await this.makeInsightCall(idList)
    } else if (this.isABTest) {
      await this.makeABTestCall(idList)
    } else if (this.isTroubleShooting) {
      await this.makeTroubleShootingCall(idList)
    } else {
      console.warn('Unknown tab')
    }
  }

  async makeInsightCall (idList: Array<string | number>) {
    const data = {
      id_list: idList,
      dsp: this.dsp,
      kpi: this.selectedKpi,
      analysis_period_start: this.startDate,
      analysis_period_end: this.endDate,
      mail_of_am: this.email
    }
    this.loadingReports = true
    const response = await this.$apiCaller.postCreateInsight(data)
    if (this.$apiCaller.isResponseError(response)) {
      const error = `Failed to create reports: ${response.errors}`
      this.$emit('error', error)
    } else {
      const success = `${response.data.success.length} report(s) will be sent to your mailbox once ready. Please notify us if not received in 12 hours`
      this.$emit('success', success)
      if (response.data.failed > 0) {
        const warning = `Failed to generate ${response.data.failed} reports: ${response.data.error}`
        this.$emit('warning', warning)
      }
    }
    this.loadingReports = false
    this.close()
  }

  async makeABTestCall (idList: Array<string | number>) {
    const data = {
      id_list: idList,
      dsp: this.dsp,
      kpi_to_optimize: this.selectedKpi,
      start_date: this.startDate,
      end_date: this.endDate,
      mail_of_am: this.email,
      custom_fee: this.customABTestFee ?? 0,
      baseline_io: this.selectedExternalIds
    }
    this.loadingReports = true
    const response = await this.$apiCaller.postCreateDeckABTest(data)
    if (this.$apiCaller.isResponseError(response)) {
      const error = `Failed to create reports: ${response.errors}`
      this.$emit('error', error)
    } else {
      const success = `${response.data.success.length} report(s) will be sent to your mailbox once ready. Please notify us if not received in 12 hours`
      this.$emit('success', success)
      if (response.data.failed > 0) {
        const warning = `Failed to generate ${response.data.failed} reports: ${response.data.error}`
        this.$emit('warning', warning)
      }
    }
    this.loadingReports = false
    this.close()
  }

  async makeTroubleShootingCall (idList: Array<string | number>) {
    const data = {
      id_list: idList,
      dsp: this.dsp,
      kpi: this.selectedKpi,
      start_date: this.startDate,
      end_date: this.endDate,
      mail_of_am: this.email
    }
    this.loadingReports = true
    const response = await this.$apiCaller.postCreateTroubleshooting(data)
    if (this.$apiCaller.isResponseError(response)) {
      const error = `Failed to create reports: ${response.errors}`
      this.$emit('error', error)
    } else {
      const success = `${response.data.success.length} report(s) will be sent to your mailbox once ready. Please notify us if not received in 12 hours`
      this.$emit('success', success)
      if (response.data.failed > 0) {
        const warning = `Failed to generate ${response.data.failed} reports: ${response.data.error}`
        this.$emit('warning', warning)
      }
    }
    this.loadingReports = false
    this.close()
  }

  get deckKey (): string {
    return this.currentTab + this.value
  }

  get isInsight (): boolean {
    return this.currentTab === 'insight'
  }

  get isABTest (): boolean {
    return this.currentTab === 'abtest'
  }

  get isTroubleShooting (): boolean {
    return this.currentTab === 'troubleshooting'
  }

  get activeSelectedIos (): SelectedIo {
    const asArray = Object.entries(this.selected)
    const filtered = asArray.filter(([key, value]) => (value.state === true))
    return Object.fromEntries(filtered)
  }

  get selectedAmount (): number {
    return Object.keys(this.activeSelectedIos).length
  }

  get kpiToOptimize (): KpiValue {
    if (Object.entries(this.activeSelectedIos).length === 1) {
      const item = Object.entries(this.activeSelectedIos)[0]
      if (Object.values(KpiValue).indexOf(item[1].instruction.KPI_to_optimize as KpiValue) >= 0) {
        return item[1].instruction.KPI_to_optimize as KpiValue
      }
    }
    return null
  }
}
